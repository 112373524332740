import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import type { RootState } from "../store";

import {
  ICreateRealityResponse,
  IInitialState,
  IReality,
  IRealityCreationData,
} from "./IReality";

const initialState: IInitialState = {
  realities: undefined,
  isLoading: false,
  isModalToAddNewRealityOpen: false,
  isModalToDeleteRealityOpen: false,
  error: undefined,
};

export const createReality = createAsyncThunk(
  "reality/createReality",
  async ({ realityName }: IRealityCreationData) => {
    const response = await axios.post(`/v1/reality/create-reality`, {
      realityName,
    });

    return response.data as ICreateRealityResponse;
  }
);

export const getRealities = createAsyncThunk(
  "reality/getRealities",
  async () => {
    const response = await axios.get(`/v1/reality/get-realities`);

    return response.data.realities as IReality[];
  }
);

export const deleteReality = createAsyncThunk(
  "reality/deleteReality",
  async (realityId: string) => {
    const response = await axios.post(`/v1/reality/delete-reality`, {
      realityId,
    });

    return response.data.realities as IReality[];
  }
);

const realitySlice = createSlice({
  name: "reality",
  initialState,
  reducers: {
    toggleAddNewRealityModal(state) {
      state.isModalToAddNewRealityOpen = !state.isModalToAddNewRealityOpen;
    },
    toggleModalToDeleteRealityOpen(state) {
      state.isModalToDeleteRealityOpen = !state.isModalToDeleteRealityOpen;
    },
    clearError(state) {
      state.error = undefined;
    },
  },
  extraReducers(builder) {
    builder.addCase(createReality.fulfilled, (state, action) => {
      state.realities = state.realities
        ? [...state.realities, action.payload!.reality!]
        : [action.payload!.reality!];
      state.isModalToAddNewRealityOpen = false;
      state.error = undefined;
    });
    builder.addCase(createReality.rejected, (state, action) => {
      state.error = "Reality with this name is already exist";
      state.isLoading = false;
    });
    builder.addCase(getRealities.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getRealities.fulfilled, (state, action) => {
      state.realities = action.payload;
      state.isLoading = false;
    });
    builder.addCase(deleteReality.fulfilled, (state, action) => {
      state.realities = action.payload;
      state.isLoading = false;
    });
  },
});

export const {
  toggleAddNewRealityModal,
  clearError,
  toggleModalToDeleteRealityOpen,
} = realitySlice.actions;

export const selectRealities = (state: RootState) => state.reality.realities;
export const selectIsRealitiesLoading = (state: RootState) =>
  state.reality.isLoading;
export const selectIsModalToAddNewRealityOpen = (state: RootState) =>
  state.reality.isModalToAddNewRealityOpen;
export const selectIsModalToDeleteRealityOpen = (state: RootState) =>
  state.reality.isModalToDeleteRealityOpen;
export const selectRealityError = (state: RootState) => state.reality.error;

export default realitySlice.reducer;
