import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface IInitialState {
  currentLinks: string;
}

const initialState: IInitialState = {
  currentLinks: "scopesRealitiesLinks",
};

const routerSlice = createSlice({
  name: "router",
  initialState,
  reducers: {
    selectLinks(state, action: PayloadAction<string>) {
      state.currentLinks = action.payload;
    },
  },
});

export const { selectLinks } = routerSlice.actions;

export const selectCurrentLinks = (state: RootState) =>
  state.router.currentLinks;

export default routerSlice.reducer;
