import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userSlice from "./user/userSlice";
import realitySlice from "./reality/realitySlice";
import scopeSlice from "./scope/scopeSlice";
import knowledgeSlice from "./knowledge/knowledgeSlice";
import voiceStyleSlice from "./voiceStyle/voiceStyleSlice";
import routerSlice from "./router/routerSlice";
import characterSlice from "./character/characterSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const rootReducer = combineReducers({
  user: userSlice,
  reality: realitySlice,
  scope: scopeSlice,
  knowledge: knowledgeSlice,
  voiceStyle: voiceStyleSlice,
  router: routerSlice,
  character: characterSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default persistReducer(persistConfig, rootReducer);
