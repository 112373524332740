import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IKhaosSettings } from "redux/user/IUser";
import type { RootState } from "../store";

import {
  IInitialState,
  ICharacter,
  ILifeExperience,
  ISequencePDNA,
} from "./ICharacter";

const initialState: IInitialState = {
  characters: [],
  PDNAResult: [],
  isLoading: true,
  selectedCharacter: undefined,
  isCreateCharacterModalOpen: false,
  isDeleteCharacterModalOpen: false,
  isKHAOSSettingsModalOpen: false,
};

export const getCharacters = createAsyncThunk(
  "character/getCharacters",
  async () => {
    try {
      const res = await axios.get("/v1/character/get-characters");

      return res.data.characters as ICharacter[];
    } catch (error) {
      console.log(error);
    }
  }
);

export const createCharacter = createAsyncThunk(
  "character/createCharacter",
  async ({ name, scopeId }: { name: string; scopeId: string }) => {
    try {
      const res = await axios.post("/v1/character/create-character", {
        name,
        scopeId,
      });

      return res.data.characters as ICharacter[];
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteCharacter = createAsyncThunk(
  "character/deleteCharacter",
  async ({ id }: { id: string }) => {
    try {
      const res = await axios.post("/v1/character/delete-character", {
        id,
      });

      return res.data.characters as ICharacter[];
    } catch (error) {
      console.log(error);
    }
  }
);

export const runPDNA = createAsyncThunk(
  "character/runPDNA",
  async ({ id, settings }: { id: string; settings: IKhaosSettings }) => {
    try {
      const res = await axios.post("/v1/character/run-pdnaSequencer", {
        id,
        settings,
      });

      return res.data.result as ISequencePDNA[];
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateLifeExperience = createAsyncThunk(
  "character/updateLifeExperience",
  async ({
    id,
    lifeExperience,
  }: {
    id: string;
    lifeExperience: ILifeExperience[];
  }) => {
    try {
      const res = await axios.put("/v1/character/update-life-experience", {
        id,
        lifeExperience,
      });

      return res.data.character as ICharacter;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateSettings = createAsyncThunk(
  "character/updateSettings",
  async (khaosSettings: IKhaosSettings) => {
    try {
      const res = await axios.put("/v1/character/update-settings", {
        khaosSettings,
      });

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const characterSlice = createSlice({
  name: "character",
  initialState,
  reducers: {
    toggleCreateCharacterModal(state) {
      state.isCreateCharacterModalOpen = !state.isCreateCharacterModalOpen;
    },
    toggleDeleteCharacterModal(state) {
      state.isDeleteCharacterModalOpen = !state.isDeleteCharacterModalOpen;
    },
    toggleKHAOSSettingsModal(state) {
      state.isKHAOSSettingsModalOpen = !state.isKHAOSSettingsModalOpen;
    },
    selectCharacter(state, action: PayloadAction<ICharacter>) {
      if (state.selectedCharacter?.id === action.payload.id) {
        state.selectedCharacter = undefined;
      } else {
        state.selectedCharacter = action.payload;
      }
    },
    unselectCharacter(state) {
      state.selectedCharacter = undefined;
    },
  },
  extraReducers(builder) {
    builder.addCase(getCharacters.fulfilled, (state, action) => {
      state.characters = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getCharacters.rejected, (state, action) => {
      state.characters = [];
      state.isLoading = true;
    });
    builder.addCase(createCharacter.fulfilled, (state, action) => {
      state.characters = action.payload;
      state.isCreateCharacterModalOpen = false;
    });
    builder.addCase(createCharacter.rejected, (state, action) => {});
    builder.addCase(deleteCharacter.fulfilled, (state, action) => {
      state.characters = action.payload;
      state.isDeleteCharacterModalOpen = false;
    });
    builder.addCase(deleteCharacter.rejected, (state, action) => {});
    builder.addCase(updateLifeExperience.fulfilled, (state, action) => {
      state.selectedCharacter = action.payload;
    });
    builder.addCase(updateLifeExperience.rejected, (state, action) => {});
    builder.addCase(runPDNA.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(runPDNA.fulfilled, (state, action) => {
      state.PDNAResult = action.payload;
      state.isLoading = false;
    });
    builder.addCase(updateSettings.fulfilled, (state, action) => {});
  },
});

export const {
  toggleCreateCharacterModal,
  toggleDeleteCharacterModal,
  toggleKHAOSSettingsModal,
  selectCharacter,
  unselectCharacter,
} = characterSlice.actions;

export const selectIsCreateCharacterModalOpen = (state: RootState) =>
  state.character.isCreateCharacterModalOpen;
export const selectIsDeleteCharacterModalOpen = (state: RootState) =>
  state.character.isDeleteCharacterModalOpen;
export const selectIsCharacterLoading = (state: RootState) =>
  state.character.isLoading;
export const selectIsKHAOSSettingsModalOpen = (state: RootState) =>
  state.character.isKHAOSSettingsModalOpen;
export const selectCharacters = (state: RootState) =>
  state.character.characters;
export const selectSelectedCharacter = (state: RootState) =>
  state.character.selectedCharacter;
export const selectPDNAResults = (state: RootState) =>
  state.character.PDNAResult;

export default characterSlice.reducer;
