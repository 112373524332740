import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "axios";

import {
  IInitialState,
  ICreateKnowledgeData,
  IKnowledge,
  IUpdateKnowledgeData,
} from "./IKnowledge";

const initialState: IInitialState = {
  knowledge: undefined,
  allKnowledge: undefined,
  isLoading: false,
  isCreatingKnowledgeModalOpen: false,
  isViewingKnowledgeModalOpen: false,
  isModalToDeleteKnowledgeOpen: false,
  isModalToViewRelationsOpen: false,
  isModalUploadKnowledgeOpen: false,
  isSuccess: false,
};

export const createKnowledge = createAsyncThunk(
  "knowledge/createKnowledge",
  async (data: ICreateKnowledgeData) => {
    const response = await axios.post(`/v1/knowledge/create-knowledge`, data);

    return response.data;
  }
);

export const createManyKnowledge = createAsyncThunk(
  "knowledge/createManyKnowledge",
  async ({
    data,
    scopeId,
  }: {
    data: ICreateKnowledgeData[];
    scopeId: string;
  }) => {
    const response = await axios.post(`/v1/knowledge/create-many-knowledge`, {
      data,
      scopeId,
    });

    return response.data;
  }
);

export const getKnowledge = createAsyncThunk(
  "knowledge/getKnowledge",
  async (knowledgeId: string) => {
    const response = await axios.get(
      `/v1/knowledge/get-knowledge/${knowledgeId}`
    );

    return response.data.knowledge as IKnowledge;
  }
);

export const getAllKnowledge = createAsyncThunk(
  "knowledge/getAllKnowledge",
  async (scopeId: string) => {
    const response = await axios.get(
      `/v1/knowledge/get-all-knowledge?scope=${scopeId}`
    );

    return response.data.knowledge as IKnowledge[];
  }
);

export const deleteKnowledge = createAsyncThunk(
  "knowledge/deleteKnowledge",
  async (knowledgeId: string) => {
    const response = await axios.post(`/v1/knowledge/delete-knowledge`, {
      knowledgeId,
    });

    return response.data;
  }
);

export const deleteRelation = createAsyncThunk(
  "knowledge/deleteRelation",
  async (data: { knowledgeId: string; relationId: string }) => {
    const response = await axios.post(`/v1/knowledge/delete-relation`, data);

    return response.data;
  }
);

export const createRelation = createAsyncThunk(
  "knowledge/createRelation",
  async (data: { type: string; knowledgeIds: string[] }) => {
    const response = await axios.post(`/v1/knowledge/create-relation`, data);

    return response.data;
  }
);

export const updateKnowledge = createAsyncThunk(
  "knowledge/updateKnowledge",
  async ({
    id,
    updateInfo,
  }: {
    id: string;
    updateInfo: IUpdateKnowledgeData;
  }) => {
    const response = await axios.put(`/v1/knowledge/update-knowledge`, {
      id,
      ...updateInfo,
    });

    return response.data;
  }
);

const knowledgeSlice = createSlice({
  name: "knowledge",
  initialState: initialState,
  reducers: {
    toggleCreatingKnowledgeModalOpen(state) {
      state.isCreatingKnowledgeModalOpen = !state.isCreatingKnowledgeModalOpen;
    },
    toggleModalToDeleteKnowledgeOpen(state) {
      state.isModalToDeleteKnowledgeOpen = !state.isModalToDeleteKnowledgeOpen;
    },
    toggleModalToViewRelationsOpen(state) {
      state.isModalToViewRelationsOpen = !state.isModalToViewRelationsOpen;
    },
    toggleModalUploadKnowledgeOpen(state) {
      state.isModalUploadKnowledgeOpen = !state.isModalUploadKnowledgeOpen;
    },
    toggleViewingKnowledgeModalOpen(state) {
      state.isLoading = true;
      state.knowledge = undefined;
      state.isViewingKnowledgeModalOpen = !state.isViewingKnowledgeModalOpen;
    },
    clearSuccess(state) {
      state.isSuccess = false;
    },
    clearKnowledgeView(state) {
      state.isLoading = true;
      state.knowledge = undefined;
    },
  },
  extraReducers(builder) {
    builder.addCase(createKnowledge.fulfilled, (state, action) => {});
    builder.addCase(getKnowledge.fulfilled, (state, action) => {
      state.isLoading = false;
      state.knowledge = action.payload;
    });
    builder.addCase(getKnowledge.rejected, (state, action) => {
      state.isLoading = true;
      state.isViewingKnowledgeModalOpen = false;
      state.knowledge = undefined;
    });
    builder.addCase(deleteKnowledge.fulfilled, (state, action) => {
      state.isLoading = true;
      state.isViewingKnowledgeModalOpen = false;
      state.knowledge = undefined;
    });
    builder.addCase(createManyKnowledge.fulfilled, (state, action) => {});
    builder.addCase(createManyKnowledge.rejected, (state, action) => {});
    builder.addCase(deleteRelation.fulfilled, (state, action) => {
      state.isSuccess = true;
    });
    builder.addCase(getAllKnowledge.fulfilled, (state, action) => {
      state.allKnowledge = action.payload;
    });
    builder.addCase(createRelation.fulfilled, (state, action) => {
      state.isSuccess = true;
    });
    builder.addCase(updateKnowledge.fulfilled, (state, action) => {});
  },
});

export const {
  toggleCreatingKnowledgeModalOpen,
  toggleViewingKnowledgeModalOpen,
  toggleModalToDeleteKnowledgeOpen,
  toggleModalToViewRelationsOpen,
  toggleModalUploadKnowledgeOpen,
  clearKnowledgeView,
  clearSuccess,
} = knowledgeSlice.actions;

export const selectKnowledge = (state: RootState) => state.knowledge.knowledge;
export const selectAllKnowledge = (state: RootState) =>
  state.knowledge.allKnowledge;
export const selectIsKnowledgeLoading = (state: RootState) =>
  state.knowledge.isLoading;
export const selectIsModalToDeleteKnowledgeOpen = (state: RootState) =>
  state.knowledge.isModalToDeleteKnowledgeOpen;
export const selectIsCreatingKnowledgeModalOpen = (state: RootState) =>
  state.knowledge.isCreatingKnowledgeModalOpen;
export const selectIsViewingKnowledgeModalOpen = (state: RootState) =>
  state.knowledge.isViewingKnowledgeModalOpen;
export const selectIsModalToViewRelationsOpen = (state: RootState) =>
  state.knowledge.isModalToViewRelationsOpen;
export const selectIsModalUploadKnowledgeOpen = (state: RootState) =>
  state.knowledge.isModalUploadKnowledgeOpen;
export const selectIsSuccess = (state: RootState) => state.knowledge.isSuccess;

export default knowledgeSlice.reducer;
