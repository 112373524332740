import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import type { RootState } from "../store";

import {
  ICreateScopeResponse,
  IInitialState,
  IPassScope,
  IScope,
  IScopeCreationData,
} from "./IScope";

const initialState: IInitialState = {
  scopes: undefined,
  isLoading: false,
  isModalToAddNewScopeOpen: false,
  error: undefined,
  isModalToDeleteScopeOpen: false,
  selectedScope: undefined,
};

export const createScope = createAsyncThunk(
  "scope/createScope",
  async ({ scopeName, realityId }: IScopeCreationData) => {
    const response = await axios.post(`/v1/scope/create-scope`, {
      scopeName,
      realityId,
    });

    return response.data as ICreateScopeResponse;
  }
);

export const deleteScope = createAsyncThunk(
  "reality/deleteScope",
  async (scopeId: string) => {
    const response = await axios.post(`/v1/scope/delete-scope`, {
      scopeId,
    });

    return response.data.scopes as IScope[];
  }
);

export const getScopes = createAsyncThunk("scope/getScopes", async () => {
  const response = await axios.get(`/v1/scope/get-scopes`);

  return response.data.scopes as IScope[];
});

const scopeSlice = createSlice({
  name: "scope",
  initialState,
  reducers: {
    toggleAddNewScopeModal(state) {
      state.isModalToAddNewScopeOpen = !state.isModalToAddNewScopeOpen;
    },
    toggleModalToDeleteScopeOpen(state) {
      state.isModalToDeleteScopeOpen = !state.isModalToDeleteScopeOpen;
    },
    clearError(state) {
      state.error = undefined;
    },
    selectScope(state, action: PayloadAction<IPassScope>) {
      if (state.selectedScope?.id === action.payload.id) {
        state.selectedScope = undefined;
      } else {
        state.selectedScope = action.payload;
      }
    },
    unselectScope(state) {
      state.selectedScope = undefined;
    },
  },
  extraReducers(builder) {
    builder.addCase(createScope.fulfilled, (state, action) => {
      state.scopes = state.scopes
        ? [...state.scopes, action.payload!.scope!]
        : [action.payload!.scope!];
      state.isModalToAddNewScopeOpen = false;
      state.error = undefined;
    });
    builder.addCase(createScope.rejected, (state, action) => {
      state.error = "Scopes with this name is already exist";
      state.scopes = undefined;
      state.isLoading = false;
    });
    builder.addCase(getScopes.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getScopes.fulfilled, (state, action) => {
      state.scopes = action.payload;
      state.isLoading = false;
    });
    builder.addCase(deleteScope.fulfilled, (state, action) => {
      state.scopes = action.payload;
      state.isLoading = false;
    });
  },
});

export const {
  toggleAddNewScopeModal,
  clearError,
  toggleModalToDeleteScopeOpen,
  selectScope,
  unselectScope,
} = scopeSlice.actions;

export const selectScopes = (state: RootState) => state.scope.scopes;
export const selectIsScopesLoading = (state: RootState) =>
  state.scope.isLoading;
export const selectIsModalToAddNewScopeOpen = (state: RootState) =>
  state.scope.isModalToAddNewScopeOpen;
export const selectIsModalToDeleteScopeOpen = (state: RootState) =>
  state.scope.isModalToDeleteScopeOpen;
export const selectScopeError = (state: RootState) => state.scope.error;
export const selectSelectedScope = (state: RootState) =>
  state.scope.selectedScope;

export default scopeSlice.reducer;
