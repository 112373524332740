import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "axios";

import { IInitialState, IVoiceStyle } from "./IVoiceStyle";

const initialState: IInitialState = {
  voiceStyles: [],
  voiceStyle: undefined,
  isLoading: false,
  isCreatingNewVoiceModalOpen: false,
  isDeleteVoiceModalOpen: false,
  isEditVoiceStylesModalOpen: false,
};

export const createVoiceStyle = createAsyncThunk(
  "voiceStyle/createVoiceStyle",
  async (data: { name: string; scopeId: string }) => {
    const response = await axios.post(
      "/v1/voice-styles/create-voice-style",
      data
    );

    return response.data;
  }
);

export const getVoiceStyles = createAsyncThunk(
  "voiceStyle/getVoiceStyles",
  async (scopeId: string) => {
    const response = await axios.get(
      `/v1/voice-styles/get-voice-styles?scope=${scopeId}`
    );

    return response.data.voiceStyles as IVoiceStyle[];
  }
);

export const getVoiceStyle = createAsyncThunk(
  "voiceStyle/getVoiceStyle",
  async (id: string) => {
    const response = await axios.get(`/v1/voice-styles/get-voice-style/${id}`);

    return response.data.voiceStyle as IVoiceStyle;
  }
);

export const deleteVoiceStyle = createAsyncThunk(
  "voiceStyle/deleteVoiceStyle",
  async (id: string) => {
    const response = await axios.post(`/v1/voice-styles/delete-voice-style`, {
      id,
    });

    return response.data;
  }
);

export const addPhrases = createAsyncThunk(
  "voiceStyle/addPhrases",
  async (data: {
    id: string;
    phrases: {
      base: string;
      style: string;
    }[];
  }) => {
    const response = await axios.post(`/v1/voice-styles/add-phrases`, data);

    return response.data;
  }
);

const voiceStyleSlice = createSlice({
  name: "voiceStyle",
  initialState,
  reducers: {
    toggleCreatingNewVoiceOpen(state) {
      state.isCreatingNewVoiceModalOpen = !state.isCreatingNewVoiceModalOpen;
    },
    toggleDeleteVoiceModalOpen(state) {
      state.isDeleteVoiceModalOpen = !state.isDeleteVoiceModalOpen;
    },
    toggleEditVoiceStylesModalOpen(state) {
      state.isEditVoiceStylesModalOpen = !state.isEditVoiceStylesModalOpen;
    },
    clearState(state) {
      state.voiceStyle = undefined;
    },
  },
  extraReducers(builder) {
    builder.addCase(createVoiceStyle.fulfilled, (state, action) => {
      state.isCreatingNewVoiceModalOpen = false;
    });
    builder.addCase(getVoiceStyles.fulfilled, (state, action) => {
      state.voiceStyles = action.payload;
    });
    builder.addCase(getVoiceStyle.fulfilled, (state, action) => {
      state.voiceStyle = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getVoiceStyle.rejected, (state, action) => {
      state.voiceStyle = undefined;
      state.isLoading = true;
    });
    builder.addCase(addPhrases.fulfilled, (state, action) => {});
  },
});

export const {
  toggleCreatingNewVoiceOpen,
  toggleDeleteVoiceModalOpen,
  toggleEditVoiceStylesModalOpen,
  clearState,
} = voiceStyleSlice.actions;

export const selectIsCreatingNewVoiceModalOpen = (state: RootState) =>
  state.voiceStyle.isCreatingNewVoiceModalOpen;
export const selectAllVoiceStyles = (state: RootState) =>
  state.voiceStyle.voiceStyles;
export const selectVoiceStyle = (state: RootState) =>
  state.voiceStyle.voiceStyle;
export const selectIsLoading = (state: RootState) => state.voiceStyle.isLoading;
export const selectIsDeleteVoiceModalOpen = (state: RootState) =>
  state.voiceStyle.isDeleteVoiceModalOpen;
export const selectIsEditVoiceStylesModalOpen = (state: RootState) =>
  state.voiceStyle.isEditVoiceStylesModalOpen;

export default voiceStyleSlice.reducer;
