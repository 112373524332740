import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "axios";

import { ILoginData, IUserState, ISignUpLoginUserResponse } from "./IUser";

const initialState: IUserState = {
  user: undefined,
  isAuth: false,
  error: undefined,
};

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (loginData: ILoginData) => {
    const response = await axios.post(`/v1/auth/login`, loginData);

    const formattedUser: ISignUpLoginUserResponse = {
      user: {
        id: response.data.user.id,
        username: response.data.user.username,
        khaosSettings: response.data.user.khaosSettings,
      },
      message: "",
    };

    if (formattedUser.user) {
      if (!formattedUser!.user!.khaosSettings) {
        formattedUser.user.khaosSettings = {
          hyperWord: {
            base: [1, 1, 1, 1],
            groupMatch: [1, 1, 1, 1],
            rootMatch: 1,
            decay: 2,
          },
          general: {
            depth: 1,
            database: 0.75,
            useDB: true,
            exceed100: true,
          },
          abstractOverlay: {
            base: [1, 1, 1, 1],
            groupMatch: [0.5, 0.5, 0.5, 0.5],
            rootMatch: 0.75,
            decay: 4,
          },
          useReturnMatchVariable: "hyper",
        };
      }
    }

    return formattedUser;
  }
);

export const checkSession = createAsyncThunk("user/checkSession", async () => {
  const response = await axios.get(`/v1/auth/check-session`);

  const formattedUser: ISignUpLoginUserResponse = {
    user: {
      id: response.data.user.id,
      username: response.data.user.username,
      khaosSettings: response.data.user.khaosSettings,
    },
    message: "",
  };

  if (formattedUser.user) {
    if (!formattedUser!.user!.khaosSettings) {
      formattedUser.user.khaosSettings = {
        hyperWord: {
          base: [1, 1, 1, 1],
          groupMatch: [1, 1, 1, 1],
          rootMatch: 1,
          decay: 2,
        },
        general: {
          depth: 1,
          database: 0.75,
          useDB: true,
          exceed100: true,
        },
        abstractOverlay: {
          base: [1, 1, 1, 1],
          groupMatch: [0.5, 0.5, 0.5, 0.5],
          rootMatch: 0.75,
          decay: 4,
        },
        useReturnMatchVariable: "hyper",
      };
    }
  }

  return formattedUser;
});

export const logoutUser = createAsyncThunk("user/logoutUser", async () => {
  await axios.post(`/v1/auth/logout`);
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.isAuth = true;
      state.error = undefined;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.user = undefined;
      state.isAuth = false;
      state.error = "Something went wrong...";
    });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      state.user = undefined;
      state.isAuth = false;
      state.error = undefined;
    });
    builder.addCase(checkSession.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.isAuth = true;
      state.error = undefined;
      state.error = undefined;
    });
    builder.addCase(checkSession.rejected, (state, action) => {
      state.user = undefined;
      state.isAuth = false;
      state.error = undefined;
      state.error = undefined;
    });
  },
});

export const selectUser = (state: RootState) => state.user.user;
export const selectIsUserAuth = (state: RootState) => state.user.isAuth;
export const selectSettings = (state: RootState) =>
  state.user.user?.khaosSettings;

export default userSlice.reducer;
